import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

const LegalForm = () => import('@/entities/legal-form/legal-form.vue');
const LegalFormDetails = () => import('@/entities/legal-form/legal-form-details.vue');

const RegistryOfCommerce = () => import('@/entities/registry-of-commerce/registry-of-commerce.vue');
const RegistryOfCommerceDetails = () => import('@/entities/registry-of-commerce/registry-of-commerce-details.vue');

const NogaCode = () => import('@/entities/noga-code/noga-code.vue');
const NogaCodeDetails = () => import('@/entities/noga-code/noga-code-details.vue');

const Company = () => import('@/entities/company/company.vue');
const CompanyUpdate = () => import('@/entities/company/company-update.vue');
const CompanyDetails = () => import('@/entities/company/company-details.vue');

const Website = () => import('@/entities/website/website.vue');
const WebsiteUpdate = () => import('@/entities/website/website-update.vue');
const WebsiteDetails = () => import('@/entities/website/website-details.vue');

const Person = () => import('@/entities/person/person.vue');
const PersonUpdate = () => import('@/entities/person/person-update.vue');
const PersonDetails = () => import('@/entities/person/person-details.vue');

const Address = () => import('@/entities/address/address.vue');
const AddressUpdate = () => import('@/entities/address/address-update.vue');
const AddressDetails = () => import('@/entities/address/address-details.vue');

const CompanyFeature = () => import('@/entities/company-feature/company-feature.vue');
const CompanyFeatureUpdate = () => import('@/entities/company-feature/company-feature-update.vue');
const CompanyFeatureDetails = () => import('@/entities/company-feature/company-feature-details.vue');

const CompanyAttributeKey = () => import('@/entities/company-attribute-key/company-attribute-key.vue');
const CompanyAttributeKeyUpdate = () => import('@/entities/company-attribute-key/company-attribute-key-update.vue');
const CompanyAttributeKeyDetails = () => import('@/entities/company-attribute-key/company-attribute-key-details.vue');

const CompanyAttribute = () => import('@/entities/company-attribute/company-attribute.vue');
const CompanyAttributeUpdate = () => import('@/entities/company-attribute/company-attribute-update.vue');
const CompanyAttributeDetails = () => import('@/entities/company-attribute/company-attribute-details.vue');

const Feedback = () => import('@/entities/feedback/feedback.vue');
const FeedbackUpdate = () => import('@/entities/feedback/feedback-update.vue');
const FeedbackDetails = () => import('@/entities/feedback/feedback-details.vue');

const BlacklistedDomain = () => import('@/entities/blacklisted-domain/blacklisted-domain.vue');
const BlacklistedDomainUpdate = () => import('@/entities/blacklisted-domain/blacklisted-domain-update.vue');
const BlacklistedDomainDetails = () => import('@/entities/blacklisted-domain/blacklisted-domain-details.vue');

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'legal-form',
      name: 'LegalForm',
      component: LegalForm,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'legal-form/:legalFormId/view',
      name: 'LegalFormView',
      component: LegalFormDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'registry-of-commerce',
      name: 'RegistryOfCommerce',
      component: RegistryOfCommerce,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'registry-of-commerce/:registryOfCommerceId/view',
      name: 'RegistryOfCommerceView',
      component: RegistryOfCommerceDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'noga-code',
      name: 'NogaCode',
      component: NogaCode,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'noga-code/:nogaCodeId/view',
      name: 'NogaCodeView',
      component: NogaCodeDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'company',
      name: 'Company',
      component: Company,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'company/new',
      name: 'CompanyCreate',
      component: CompanyUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'company/:companyId/edit',
      name: 'CompanyEdit',
      component: CompanyUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'company/:companyId/view',
      name: 'CompanyView',
      component: CompanyDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'website',
      name: 'Website',
      component: Website,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'website/new',
      name: 'WebsiteCreate',
      component: WebsiteUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'website/:websiteId/edit',
      name: 'WebsiteEdit',
      component: WebsiteUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'website/:websiteId/view',
      name: 'WebsiteView',
      component: WebsiteDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'person',
      name: 'Person',
      component: Person,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'person/new',
      name: 'PersonCreate',
      component: PersonUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'person/:personId/edit',
      name: 'PersonEdit',
      component: PersonUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'person/:personId/view',
      name: 'PersonView',
      component: PersonDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'address',
      name: 'Address',
      component: Address,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'address/new',
      name: 'AddressCreate',
      component: AddressUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'address/:addressId/edit',
      name: 'AddressEdit',
      component: AddressUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'address/:addressId/view',
      name: 'AddressView',
      component: AddressDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'company-feature',
      name: 'CompanyFeature',
      component: CompanyFeature,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'company-feature/new',
      name: 'CompanyFeatureCreate',
      component: CompanyFeatureUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'company-feature/:companyFeatureId/edit',
      name: 'CompanyFeatureEdit',
      component: CompanyFeatureUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'company-feature/:companyFeatureId/view',
      name: 'CompanyFeatureView',
      component: CompanyFeatureDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'company-attribute-key',
      name: 'CompanyAttributeKey',
      component: CompanyAttributeKey,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'company-attribute-key/new',
      name: 'CompanyAttributeKeyCreate',
      component: CompanyAttributeKeyUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'company-attribute-key/:companyAttributeKeyId/edit',
      name: 'CompanyAttributeKeyEdit',
      component: CompanyAttributeKeyUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'company-attribute-key/:companyAttributeKeyId/view',
      name: 'CompanyAttributeKeyView',
      component: CompanyAttributeKeyDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'company-attribute',
      name: 'CompanyAttribute',
      component: CompanyAttribute,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'company-attribute/new',
      name: 'CompanyAttributeCreate',
      component: CompanyAttributeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'company-attribute/:companyAttributeId/edit',
      name: 'CompanyAttributeEdit',
      component: CompanyAttributeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'company-attribute/:companyAttributeId/view',
      name: 'CompanyAttributeView',
      component: CompanyAttributeDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'feedback',
      name: 'Feedback',
      component: Feedback,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'feedback/new',
      name: 'FeedbackCreate',
      component: FeedbackUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'feedback/:feedbackId/edit',
      name: 'FeedbackEdit',
      component: FeedbackUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'feedback/:feedbackId/view',
      name: 'FeedbackView',
      component: FeedbackDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'blacklisted-domain',
      name: 'BlacklistedDomain',
      component: BlacklistedDomain,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'blacklisted-domain/new',
      name: 'BlacklistedDomainCreate',
      component: BlacklistedDomainUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'blacklisted-domain/:blacklistedDomainId/edit',
      name: 'BlacklistedDomainEdit',
      component: BlacklistedDomainUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'blacklisted-domain/:blacklistedDomainId/view',
      name: 'BlacklistedDomainView',
      component: BlacklistedDomainDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
