import { Module } from 'vuex';

export const searchStore: Module<any, any> = {
  state: {
    currentType: '',
    currentFilters: null,
  },
  getters: {
    currentType: state => state.currentType,
    currentFilters: state => state.currentFilters,
  },
  mutations: {
    setType(state, type) {
      state.currentType = type;
    },
    setFilters(state, filters) {
      state.currentFilters = filters;
    },
  },
};
