var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("b-dropdown-item", { attrs: { to: "/company" } }, [
        _c(
          "span",
          {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.entities.company")),
            },
          },
          [_vm._v("Company")]
        ),
      ]),
      _vm._v(" "),
      _c("b-dropdown-item", { attrs: { to: "/website" } }, [
        _c(
          "span",
          {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.entities.website")),
            },
          },
          [_vm._v("Website")]
        ),
      ]),
      _vm._v(" "),
      _c("b-dropdown-item", { attrs: { to: "/person" } }, [
        _c(
          "span",
          {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.entities.person")),
            },
          },
          [_vm._v("Person")]
        ),
      ]),
      _vm._v(" "),
      _c("b-dropdown-item", { attrs: { to: "/address" } }, [
        _c(
          "span",
          {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.entities.address")),
            },
          },
          [_vm._v("Address")]
        ),
      ]),
      _vm._v(" "),
      _c("b-dropdown-item", { attrs: { to: "/blacklisted-domain" } }, [
        _c(
          "span",
          {
            domProps: {
              textContent: _vm._s(
                _vm.$t("global.menu.entities.blacklistedDomain")
              ),
            },
          },
          [_vm._v("Blacklisted Domain")]
        ),
      ]),
      _vm._v(" "),
      _c("b-dropdown-divider"),
      _vm._v(" "),
      _c("b-dropdown-item", { attrs: { to: "/company-feature" } }, [
        _c(
          "span",
          {
            domProps: {
              textContent: _vm._s(
                _vm.$t("global.menu.entities.companyFeature")
              ),
            },
          },
          [_vm._v("Company Feature")]
        ),
      ]),
      _vm._v(" "),
      _c("b-dropdown-item", { attrs: { to: "/company-attribute-key" } }, [
        _c(
          "span",
          {
            domProps: {
              textContent: _vm._s(
                _vm.$t("global.menu.entities.companyAttributeKey")
              ),
            },
          },
          [_vm._v("Company Attribute Key")]
        ),
      ]),
      _vm._v(" "),
      _c("b-dropdown-item", { attrs: { to: "/company-attribute" } }, [
        _c(
          "span",
          {
            domProps: {
              textContent: _vm._s(
                _vm.$t("global.menu.entities.companyAttribute")
              ),
            },
          },
          [_vm._v("Company Attribute")]
        ),
      ]),
      _vm._v(" "),
      _c("b-dropdown-divider"),
      _vm._v(" "),
      _c("b-dropdown-item", { attrs: { to: "/noga-code" } }, [
        _c(
          "span",
          {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.entities.nogaCode")),
            },
          },
          [_vm._v("Noga Code")]
        ),
      ]),
      _vm._v(" "),
      _c("b-dropdown-divider"),
      _vm._v(" "),
      _c("b-dropdown-item", { attrs: { to: "/legal-form" } }, [
        _c(
          "span",
          {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.entities.legalForm")),
            },
          },
          [_vm._v("Legal Form")]
        ),
      ]),
      _vm._v(" "),
      _c("b-dropdown-item", { attrs: { to: "/registry-of-commerce" } }, [
        _c(
          "span",
          {
            domProps: {
              textContent: _vm._s(
                _vm.$t("global.menu.entities.registryOfCommerce")
              ),
            },
          },
          [_vm._v("Registry Of Commerce")]
        ),
      ]),
      _vm._v(" "),
      _c("b-dropdown-divider"),
      _vm._v(" "),
      _c("b-dropdown-item", { attrs: { to: "/feedback" } }, [
        _c(
          "span",
          {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.entities.feedback")),
            },
          },
          [_vm._v("Feedback")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }